import gql from 'graphql-tag';

const getEmpleadosQueryFiniquito = gql`
    query empleados($whereConditions:EmpleadosWhereWhereConditions, $numberItems: Int!, $numberPage: Int!, $fieldOrder: String!, $Order: SortOrder!) {
        empleados(where:$whereConditions,first: $numberItems, page: $numberPage, orderBy:[{field: $fieldOrder, order: $Order}]) {
            data {
                id,
                puesto_id
                nombre_completo,
                nombre,
                apellido_paterno,
                apellido_materno,
                foto,
                puesto{
                    nombre
                }
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`

const getEmpleadosQueryAguinaldo = gql`
    query empleados($whereConditions:EmpleadosWhereWhereConditions, $numberItems: Int!, $numberPage: Int!, $fieldOrder: String!, $Order: SortOrder!) {
        empleados(where:$whereConditions,first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: $Order}]) {
            data {
                id,
                puesto_id
                nombre_completo,
                nombre,
                apellido_paterno,
                apellido_materno,
                foto,
                puesto{
                    nombre
                }
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`

const queries = { getEmpleadosQueryFiniquito, getEmpleadosQueryAguinaldo };

export default queries;
