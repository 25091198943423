<template>
    <ValidationObserver ref="form" v-slot="{ invalid }" style="width:100%">
        <v-row class="ma-0 pa-0">  
            <template v-for="(item, index) in items">
                <template v-if="item.tipo == 'INTEGER'">
                    <v-col cols="12" xs="12" sm="4" md="4" lg="3" xl="3" class="py-0">
                        <v-text-field
                            :id="'text-field-' + item.id"
                            v-model="fields.values[index]"
                            outlined
                            :label="item.nombre"
                            v-mask="'#######'"
                            :disabled="fields.disabled[index]"
                        />
                    </v-col>
                </template>

                <template v-if="item.tipo == 'FLOAT'">
                    <v-col cols="12" xs="12" sm="4" md="4" lg="3" xl="3" class="py-0">
                        <v-text-field
                            outlined
                            :label="item.nombre"
                            class="pa-0 ma-0"
                            v-on:keypress="presicionNumero($event, fields.values[index])"
                            oninput="if(this.value<0) this.value = 0; this.value=this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                            v-model="fields.values[index]"
                            :disabled="fields.disabled[index]"
                        >
                        </v-text-field>
                    </v-col>
                </template>
                <template v-if="item.tipo == 'DATE'">
                    <v-col cols="12" xs="12" sm="4" md="4" lg="3" xl="3" class="py-0">
                        <VuetifyDatePicker
                            outlined
                            readonly
                            v-model="fields.values[index]"
                            :valorDefecto="fields.values[index]"
                            :label="item.nombre"
                            placeholder="dd/mm/aaaa"
                            :min="min"
                            :max="max"
                            ref="Fecha"
                            rules="required"
                            :notAllowedDates="usedDates"
                        />
                    </v-col>
                </template>
                
            </template>
            <v-col cols="12" xs="12" sm="4" md="4" lg="3" xl="3" class="py-0 mt-2">
                <v-btn  class="" color="#1E2245"  elevation="0" dark fab  @click="agregar()" small>
                    <v-icon dark>add</v-icon>
                </v-btn>
            </v-col>



            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="px-1 py-0" v-if="horasExtra.length != 0 ">
                <h3 class="pl-4 title-horas">Listado de horas extra</h3>
                <table style="width:100%" class="mx-5">
                    <thead class="tbl-header">
                        <tr>
                            <th>Fecha</th>
                            <th>Horas dobles</th>
                            <th>Horas triples</th>
                            <th>Importe</th>
                            <th class="text-center">Opciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in horasExtra" :key="index">
                            <td>
                                {{dateFormat(item.horasFecha)}} 
                            </td>
                            <td>
                                {{item.horasDobles}} 
                            </td>
                            <td>
                                {{item.horasTriples}} 
                            </td>
                            <td>
                                {{item.horasImporte}} 
                            </td>
                            <td class="text-center">
                                <v-btn class="botonHover" icon fab dark small @click="eliminar(horasExtra.indexOf(item), item)" >
                                    <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </tbody>
                </table>
                
            </v-col>


            <!-- <v-row class="ma-0 pa-0 mt-4" v-if="horasExtra.length" v-cloak>
                <h3 class="pl-4 title-horas">Listado de horas extra</h3>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="px-1 py-0" v-for="(item, index) in horasExtra" :key="index">
                    <v-row class="ma-0 pa-0 horas">
                        <v-col 
                            cols="12" xs="3" sm="3" md="3" lg="3" xl="3"
                            class="py-0"
                        >
                            Fecha: {{dateFormat(item.horasFecha)}} 
                        </v-col>
                        <v-col 
                            cols="12" xs="3" sm="3" md="3" lg="3" xl="3"
                            class="py-0"
                            v-if="funcion == 'p_HorasExtrasDobles' && item.horasDobles != 0 "
                        >
                            Horas dobles: {{item.horasDobles}} 
                        </v-col>
                        <v-col 
                            v-if="item.horasTriples != 0" 
                            cols="12" xs="3" sm="3" md="3" lg="3" xl="3"
                            class="py-0"
                        >
                            Horas triples: {{item.horasTriples}} 
                        </v-col>
                        <v-col 
                            v-else 
                            cols="12" xs="3" sm="3" md="3" lg="3" xl="3"
                            class="py-0"
                        ></v-col>
                        <v-col 
                            cols="12" xs="3" sm="3" md="3" lg="3" xl="3" 
                            class="d-flex py-0 my-0"
                        >
                            <v-btn class="botonHover" icon fab dark small @click="eliminar(horasExtra.indexOf(item), item)" >
                                <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                            </v-btn>
                        </v-col>
                        <hr/>
                    </v-row>
                </v-col>
            </v-row> -->

        </v-row>
    </ValidationObserver>
</template>
<script>
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
import moment from 'moment';
moment.locale('es');
export default {
    components: {
        VuetifyDatePicker,
    },
    name: 'componente-horas-extras',
    props: {
        items: { 
            type: Array, 
            default: () => [] 
        },
        usedDates: { 
            type: Array, 
            default: () => [] 
        },
        weekHours: { 
            type: Array, 
            default: () => [] 
        },
        keyItem: {
            type: Number,
            default: undefined
        },
        funcion: {
            type: String,
            default: undefined
        },
        last:{
            type: Boolean,
            default: false
        },
        edit: {
            type: Boolean,
            default: false
        },
        actions: {
            type: Boolean,
            default: false
        },
        min: {
            type: String,
            default: undefined
        },
        max: {
            type: String,
            default: undefined
        },
    },
    data() {
        return {
            fields: {
                values: [],
                rule: [],
                disabled:[]
            },
            horasExtra:[],
            options: {
                locale: "es-MX",
                prefix: "$",
                suffix: "",
                length: 11,
                precision: 2
            },
            optionPorcentaje: {
                locale: "es-MX",
                prefix: "",
                suffix: "%",
                length: 5,
                precision: 2
            },
        }
    },
    watch:{
        "fields.values": function (newValue) {
            if (this.fields.values[0] != undefined && this.fields.values[0] != "" && this.fields.values[0] != null ){
                this.fields.disabled[0] = false;
                this.fields.disabled[1] = true;
                this.fields.values[1] = "";
            }
            else if (this.fields.values[1] != undefined && this.fields.values[1] != ""  && this.fields.values[1] != null){
                this.fields.disabled[0] = true;
                this.fields.disabled[1] = false;
                this.fields.values[0] = "";
            }
            else if(
                (this.fields.values[0] == undefined && this.fields.values[1] == undefined) ||
                (this.fields.values[0] == "" && this.fields.values[0] == "")    ||
                (this.fields.values[0] == null && this.fields.values[0] == null)
            ){
                this.fields.disabled[0] = false;
                this.fields.disabled[1] = false;
            }
        }
    },
    methods: {
        agregar(){
            this.$refs.form.validate().then(success => {
                if(!success){
                    return
                }

                const horasDobleTopeDiario = 3
                const horasDobleTopeSemanal = 9
                let horas = (this.fields.values[0] != null && this.fields.values[0] != "") ? parseInt(this.fields.values[0]):this.fields.values[0]
                let importe = (this.fields.values[1] != null && this.fields.values[1] != "") ? parseFloat(this.fields.values[1]):this.fields.values[1]
                let fecha = this.fields.values[2]

                let objHoras = {}

                
                /* let minFecha = moment(this.min);
                let maxFecha = moment(this.max);
                let resultsFecha = []
                while (minFecha.isSameOrBefore(maxFecha)) {
                    resultsFecha.push(minFecha.format('YYYY-MM-DD'));
                    minFecha.add(1, 'days');
                }
                let numeroDeSemanasPeriodo = Math.ceil(resultsFecha.length/7);
                console.log(Math.ceil(resultsFecha.length/7)); */


                /* const limit = 7;
                const maxHorasDobles = 9;
                let date = moment(this.min);
                let count = 0;
                let week = 0;
                let weeks = [];

                while(!date.isSameOrAfter(moment(this.max).add(1,"d"))){
                    if(count == 0){
                        weeks.push({fechas:[],horasDobles:maxHorasDobles});
                    }
                        
                    if(count < limit){
                        let newDate = moment(date.format("YYYY-MM-DD"));
                        weeks[week].fechas.push(newDate);
                        date = date.add(1,"d");
                        count++;
                    }else{
                        count = 0;
                        week++;
                    }
                }

                let numeroDeSemanasPeriodo = weeks; */
                
               
                if(this.funcion == 'p_HorasExtrasDobles'){
                    if(horas > horasDobleTopeDiario){
                        objHoras.horasDobles = horasDobleTopeDiario
                        objHoras.horasTriples = horas - horasDobleTopeDiario;
                        objHoras.horasFecha = fecha
                        objHoras.horasImporte = ""
                    }
                    else{
                        objHoras.horasDobles = horas
                        objHoras.horasTriples = ""
                        objHoras.horasFecha = fecha
                        objHoras.horasImporte = importe
                    }
                }
                if(this.funcion == 'p_HorasExtrasTriples'){
                    objHoras.horasDobles = ''
                    objHoras.horasTriples = horas
                    objHoras.horasFecha = fecha
                    objHoras.horasImporte = importe
                }
                

                this.horasExtra.push(objHoras);
                this.usedDates.push(fecha)
                this.fields.values[0] = null;
                this.fields.values[1] = null;
                this.fields.values[2] = null;
                this.$nextTick(() => {
                    this.$refs.form.reset();
                });
                        
            }); 
        },

        eliminar(i, item) {
            this.usedDates.splice(i, 1);
            this.horasExtra.splice(i, 1);
        },
        dateFormat(fecha) {
            if(fecha == null){
                return;
            }
            let fecha2 = fecha.split("-");
            
            return fecha2[2] + "/" + fecha2[1]+ "/" + fecha2[0];
            
        },
        getData() {
            let arrayHoras = [];

            if(this.horasExtra.length == 0){
                
            }
            else{
                this.horasExtra.forEach(element => {
                    arrayHoras.push(
                        {
                            0:{
                                field   : "horas",
                                type    : "INTEGER",
                                value   : element.horasDobles + element.horasTriples
                            },
                            1:{
                                field   : "importe",
                                type    : "FLOAT",
                                value   : element.horasImporte
                            },
                            2:{
                                field   : "fecha",
                                type    : "DATE",
                                value   : element.horasFecha
                            }
                        }
                    );

                });
                return arrayHoras

            }
        },
        presicionNumero(event, valor){
            if (/\.\d{2}/.test(valor)) 
                return event.preventDefault();
        },
    },
}
</script>
<style scoped>
    [v-cloak] {
        display: none;
    }
    .title-horas{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        color: #1E2245;
    }
    .horas{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: #828282;
        display: flex;
        align-items: center;
    }
</style>