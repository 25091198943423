<template style="border-radius: 10px">
    <v-card id="main" class="scrollbar-template" style="height:100%; overflow-y:scroll; border-radius: 10px">
        <div class="header pb-7">
            <img :src="imagen" class="imgModal" />
            <h2 class="title">Agregar complementos de nómina</h2>
        </div>
        <v-divider class="px-9 mx-9 mb-1"></v-divider>
        <div class="container">
            <v-row class="py-0 my-0">
                <v-col cols="12" xs="12" sm="4" md="4" class="py-0 my-0">
                    <v-autocomplete
                        outlined
                        label="Empleados"
                        v-model="empleado_id"
                        :items="itemsEmpleados"
                        item-text="nombre_completo"
                        item-value="id"
                        persistent-hint
                        class="pa-0 ma-0"
                        id="cmbEmpleados"
                    />
                </v-col>
                <v-col cols="12" xs="12" sm="4" md="4" class="py-0 my-0">
                    <v-autocomplete
                        outlined
                        label="Tipo"
                        v-model="tipo"
                        :items="tipoCatalogo"
                        item-text="nombre"
                        item-value="valor"
                        persistent-hint
                        class="pa-0 ma-0"
                        id="cmbTipo"
                    />
                </v-col>

                <v-col cols="12" xs="12" sm="4" md="4" class="py-0 my-0">
                    <v-autocomplete
                        outlined
                        label="Tipo incidencia"
                        v-model="tipo_incidencia_id"
                        :items="tipos_incidencias"
                        item-text="codigoNombre"
                        item-value="id"
                        persistent-hint
                        class="pa-0 ma-0"
                        required 
                        id="cmbTipoIncidencia"
                    />
                </v-col>
            </v-row>

            <v-row class="py-0 mt-4 mb-3">
                <v-col v-if="!horasExtra" cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0 my-0">
                    <dynamic-input 
                        v-if="campos.length > 0" :key="componentKey" 
                        ref="dynamicInput" 
                        :items="campos" 
                        :funcion="funcion" 
                        :min="inicio_periodo" 
                        :max="final_periodo"
                        :isWatch="isWatch"
                    />
                </v-col>
                <template v-if="horasExtra">

                    <ComponentHorasDoblesTriples 
                        :key="componentKey"
                        :items="campos" 
                        :funcion="funcion" 
                        :min="inicio_periodo" 
                        :max="final_periodo"
                        ref="componentHoras"
                    />

                    <!-- <v-col cols="12" lg="12">Periodo de nómina del {{dateFormatLeters(inicio_periodo)}} al {{dateFormatLeters(final_periodo)}}</v-col>
                    <template v-if="usedDates.length > 0">
                        <template v-for="(week,indexW) in weekComponets">
                            <template v-if="week.length > 0">
                                <v-col cols="12" lg="12" v-if="indexW != weekComponets.length-1" :key="indexW">
                                    {{`Semana - ${indexW+1} del: ${dateFormatLeters(weekHours[indexW].fechas[0])} al ${dateFormatLeters(weekHours[indexW].fechas[weekHours[indexW].fechas.length - 1])}`}}
                                </v-col> 
                                <v-col v-for="(item, index) in week" :key="index" cols="12" lg="12" >
                                    <dynamic-input 
                                    :key="item[0].key" 
                                    :keyItem="item[0].key" 
                                    :ref="'dynamicInput'+item[0].key" 
                                    :items="item" :funcion="funcion" 
                                    :last="weekComponets[indexW].length == (index+1)?true:false" 
                                    :usedDates="usedDates" 
                                    :weekHours="weekHours" 
                                    :min="inicio_periodo" 
                                    :max="final_periodo" 
                                    actions 
                                    @agregarCampo="agregarCampo" 
                                    @eliminarCampo="eliminarCampo"
                                    @updateUsedDates="updateUsedDates" 
                                    @updateWeekHours="updateWeekHours"
                                    />
                                </v-col>
                            </template>
                        </template>
                    </template>
                    <template v-else>
                        <v-col v-for="(item, index) in itemsCampos" :key="index" cols="12" lg="12" >
                            <dynamic-input 
                            :key="item[0].key" 
                            :keyItem="index" 
                            :ref="'dynamicInput'+item[0].key" 
                            :items="item" :funcion="funcion" 
                            :last="itemsCampos.length == (index+1)?true:false" 
                            :usedDates="usedDates" 
                            :weekHours="weekHours" 
                            :min="inicio_periodo" 
                            :max="final_periodo" 
                            actions 
                            @agregarCampo="agregarCampo" 
                            @eliminarCampo="eliminarCampo"
                            @updateUsedDates="updateUsedDates" 
                            @updateWeekHours="updateWeekHours"
                            />
                        </v-col>
                    </template> -->

                </template>
                <v-col v-if="!temporal && !horasExtra" class="py-0">
                    <v-switch class="pa-0" v-model="repetir" label="Repetir cada periodo"></v-switch>
                </v-col>
                <v-col v-if="showSwitchIndemnizacion" class="py-0" >
                    <v-switch class="pa-0" v-model="tipo_indemnizacion" label="Incluir en indemnización"></v-switch>
                </v-col>
            </v-row>
            <v-row class="py-0 my-0">
                <v-col cols="12" lg="12" class="py-0 my-0">
                    <v-textarea
                        outlined
                        class="pa-0 ma-0"
                        label="Descripción"
                        v-model="descripcion"
                        rows="3"
                        hide-details
                    ></v-textarea>
                </v-col>
            </v-row>
            <v-row  class="py-0 my-0" style="margin-top:34px !important; margin-bottom:34px !important;">
                <v-col cols="12" lg="12" class="py-0"> 
                    <v-btn class="btnGuardar float-right" :loading="btnStore" @click="store()">Agregar</v-btn>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            
            <h3 class="title mt-7 ml-0 mb-4">Detalle de complementos</h3>

            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                    <table class="tbl-incidencias">
                        <thead class="tbl-header">
                            <tr>
                                <th class="pl-2">Empleado</th>
                                <th>Código</th>
                                <th>Valor1</th>
                                <th>Valor2</th>
                                <th>Valor3</th>
                                <th class="text-center" v-if="!temporal">Repetir</th>
                                <th class="text-end">Total</th>
                                <th class="text-center">Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="tableData.length == 0" >
                                <td class="emptyTableComplementos" :colspan="!temporal?8:7">
                                    No hay elementos para mostrar
                                </td>
                            </tr>
                            <template v-else v-for="(item, index) in tableData">
                                <!-- <tr :key="index">
                                    <td colspan="6" class="pl-4">{{item.nombre_completo}}</td>
                                </tr> -->
                                    <template v-for="(item2, index2) in item.incidencias">
                                        <tr :key="index2">
                                            <td><span v-if="index2 == 0">{{item.nombre_completo}}</span></td>
                                            <td>{{ item2.tipo }}</td>
                                            <td>
                                                <div v-if="item2.campos[0]">
                                                    <template v-if="item2.campos[0].type === 'DATE'">{{ dateFormat(item2.campos[0].value) }}</template> 
                                                    <template v-else-if="item2.campos[0].type === 'FLOAT' && item2.campos[0].field === 'importe'">{{ formatoMoneda(item2.campos[0].value) }}</template> 
                                                    <template v-else-if="item2.campos[0].type === 'FLOAT' && item2.campos[0].field === 'porcentaje'">{{ item2.campos[0].value }} %</template> 
                                                    <template v-else>{{item2.campos[0].value}}</template>
                                                </div>
                                            </td>
                                            <td>
                                                <div v-if="item2.campos[1]">
                                                    <template v-if="item2.campos[1].type === 'DATE'">{{ dateFormat(item2.campos[1].value) }}</template> 
                                                    <template v-else-if="item2.campos[1].type === 'DATEMULTIPLE'">{{item2.campos[1].value}}</template>
                                                    <template v-else-if="item2.campos[1].type === 'FLOAT' && item2.campos[1].field === 'importe'">{{ formatoMoneda(item2.campos[1].value) }}</template> 
                                                    <template v-else-if="item2.campos[1].type === 'FLOAT' && item2.campos[1].field === 'porcentaje'">{{ item2.campos[1].value }} %</template> 
                                                    <template v-else>{{item2.campos[1].value}}</template>
                                                </div>
                                            </td>
                                            <td>
                                                <div v-if="item2.campos[2]">
                                                    <template v-if="item2.campos[2].type === 'DATE'">{{ dateFormat(item2.campos[2].value) }}</template> 
                                                    <template v-else-if="item2.campos[2].type === 'FLOAT' && item2.campos[2].field === 'importe'">{{ formatoMoneda(item2.campos[2].value) }}</template> 
                                                    <template v-else-if="item2.campos[2].type === 'FLOAT' && item2.campos[2].field === 'porcentaje'">{{ item2.campos[2].value }} %</template> 
                                                    <template v-else>{{item2.campos[2].value}}</template>
                                                </div>
                                            </td>
                                            <td class="text-center" v-if="!temporal">{{ item2.repetir?'SI':'NO' }}</td>
                                            <td class="text-end" :style="item2.totales.valor_incidencia<0?'color:#D93025':''">{{ formatoMoneda(item2.totales.valor_incidencia) }}</td>
                                            <td class="d-flex align-center">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(item2,index)">
                                                            <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Eliminar</span>
                                                </v-tooltip>
                                                <v-img
                                                    v-if="item2.totales.valor_incidencia<0"
                                                    class="mt-1 mb-2"
                                                    max-height="23"
                                                    max-width="23"
                                                    src="/static/svg/signoExclamacion.svg"
                                                ></v-img>
                                            </td> 
                                        </tr>
                                    </template>
                                    <tr :key="'a'+index">
                                        <td :colspan="!temporal?8:7">
                                            <v-divider></v-divider>
                                        </td>
                                    </tr>
                                    
                            </template>
                        </tbody>
                    </table>
                </v-col>
            </v-row>

            <v-row class="mx-0 px-0">
                <v-col cols="12" lg="12" class="px-0 float-right">
                    <!-- <v-btn class="btnGuardar modal-pull-right" @click="!temporal?guardar():guardarTemporal()" :loading="disableGuardar">Guardar</v-btn> -->
                    <v-btn class="btnGuardar modal-pull-right" @click="preGuardar()" :loading="disableGuardar">Guardar</v-btn>
                    <button class="btnCerrar modal-pull-right" @click="cancel()">Cerrar</button>  
                </v-col>
            </v-row>
        </div>
    </v-card>
</template>

<script>

import PerfectScrollbar from 'perfect-scrollbar';
import DynamicInput from '@/components/DynamicInput.vue';
import ComponentHorasDoblesTriples from '@/components/ComponentHorasDoblesTriples.vue'
import IncidenciasNominasApi from '@/api/nominas/incidencias';
import Notify from '@/plugins/notify';
import queries from '@/queries/FiniquitoAguinaldo';
import incidencias from '../api/incidencias';
import { validate } from 'graphql';
import moment from 'moment';

export default {
    components: {
        DynamicInput,
        ComponentHorasDoblesTriples
    },
    props: ['objPeriodoIncidencia'],
    watch: {
        tipo_incidencia_id: function(value) {
            if(value != null){
                //console.log(value);
                let result  = this.tipos_incidencias.filter(item => item.id == value);

                this.funcion = result[0].funcion_nombre;
                

                /* Se quita la validacion de p_Sueldo */

                this.itemsCampos=[];
                
                if(this.funcion == "p_HorasExtrasDobles" || this.funcion == "p_HorasExtrasTriples"){
                    this.horasExtra=true;
                    this.campos = result[0].campos;
                    this.agregarCampo();
                    
                }else{
                    this.forceRerender();    
                    this.campos = result[0].campos;  
                    this.isWatch = result[0].isWatch;
                    this.horasExtra = false;
                    this.usedDates = [];   
                    this.itemsCampos = [];   
                }
                


                if(this.objPeriodoIncidencia.tipo_periodo_especial == 'FINIQUITO'){
                    if(
                        this.tipo == 'deducciones'
                        
                    ){
                        this.showSwitchIndemnizacion = true;
                        this.tipo_indemnizacion = false;
                    }
                    else if(
                        this.tipo == 'percepciones' &&
                        (this.funcion == "p_PrimaAntiguedad" ||
                        this.funcion == "p_Indemnizacion" ||
                        this.funcion == "p_IndemnizacionServicio" ||
                        this.funcion == "p_GratificacionSeparacion")
                    ){
                        this.showSwitchIndemnizacion = false;
                        this.tipo_indemnizacion = true;
                    }
                    else{
                        this.showSwitchIndemnizacion =  false;
                        this.tipo_indemnizacion = false;
                    }

                }

            }
        },
        tipo: function(value){
            if(value == 'percepciones'){
                this.tipos_incidencias = this.percepciones;
                this.tipo_incidencia_id = null;
            }
            else if(value == 'deducciones'){
                this.tipos_incidencias = this.deducciones;
                this.tipo_incidencia_id = null;   
                this.itemsCampos=[];             
            }
            else{
                this.tipos_incidencias = this.otrosPagos;
                this.tipo_incidencia_id = null;    
                this.itemsCampos=[];            
            }
        },
        empleado_id: function(value){
            //this.tableData = [];
        }
    },
    data() {
        return {
            rol: null,
            datosLogin: null,
            dataNew:null,
            itemIncidencia:[],
            itemsEmpleados: [],
            usedDates:[],
            weekHours:[],
            empleado_id: null,
            tipos_incidencias: [],
            tipo_incidencia_id: null,
            itemsCampos:[],
            weekComponets:[],
            campos: [],
            tableData: [],
            descripcion: '',
            tipoCatalogo : [
                {id:1, nombre:"Percepciones",valor:"percepciones"}, 
                {id:2, nombre:"Deducciones", valor:"deducciones"},
                {id:3, nombre:"Otros pagos", valor:"otros_pagos"}
            ],
            percepciones: [],
            deducciones : [],
            otrosPagos  : [],
            tipo: 'percepciones',
            componentKey: 0,
            indexIncidencia:0,
            temporal : false,
            horasExtra : false,
            imagen:"/static/icon/complemento_nomina.png",
            inicio_periodo : null,
            final_periodo : null,
            funcion:null,
            repetir: false,
            tipo_indemnizacion      : false,
            disableGuardar          : false,
            btnStore                : false,
            isWatch                 : false,
            showSwitchIndemnizacion : false,
        }
    },
    methods: {
        dateFormatLeters(fecha) {
            
            return moment(fecha).format("Do [de] MMMM [del] YYYY");
        },
        getEmpleados() {
            let params = {
                //periodo_incidencia_id : this.objPeriodoIncidencia.id
                periodo_incidencia_id   : !this.temporal?this.objPeriodoIncidencia.id:this.objPeriodoIncidencia.periodo_incidencia_id
            };

            IncidenciasNominasApi.getEmpleados(params)
                .then(response => {
                    this.itemsEmpleados = response.data;
                })
                .catch(error => console.error(error));
        },
        async getTiposIncidencias() {
            let params = {
                cliente_id  : this.objPeriodoIncidencia.cliente_id,
                activo      : true
            };

            await IncidenciasNominasApi.getTiposIncidencias(params).then(response => {
                let format = response.data;
                if(response.data.length != 0){
                    format.forEach( item => {
                        if(
                            this.objPeriodoIncidencia.tipo_periodo_especial == "AGUINALDO" &&
                            (//item.funcion_nombre != 'p_Sueldo' &&
                            //item.funcion_nombre != 'p_Aguinaldo' &&
                            item.funcion_nombre != 'p_Vacaciones' &&
                            item.funcion_nombre != 'p_Indemnizacion' &&
                            item.funcion_nombre != 'p_diasPagar' &&
                            item.funcion_nombre != 'p_PrimaAntiguedad' &&
                            item.funcion_nombre != 'p_HonorariosAsimilados' &&
                            item.funcion_nombre != 'p_IndemnizacionServicio')
                        ){
                            item.codigoNombre = item.codigo + ' - ' + item.nombre

                            switch (item.codigo.charAt(0)) {
                                case 'P':
                                    this.percepciones.push(item);
                                    break;
                                case 'D':
                                    this.deducciones.push(item);
                                    break;

                                //No hay otros pagos en Aguinaldos
                                /* case 'O':
                                    this.otrosPagos.push(item);
                                    break;  */   
                                default:
                                    break;
                            }
                        }
                        else if( this.objPeriodoIncidencia.tipo_periodo_especial != "AGUINALDO"){
                            item.codigoNombre = item.codigo + ' - ' + item.nombre

                            switch (item.codigo.charAt(0)) {
                                case 'P':
                                    this.percepciones.push(item);
                                    break;
                                case 'D':
                                    this.deducciones.push(item);
                                    break;
                                case 'O':
                                    this.otrosPagos.push(item);
                                    break;    
                                default:
                                    break;
                            }
                        }
                        
                    })
                }
                
                this.tipos_incidencias = this.percepciones;
            })
            .catch(error => console.error(error));
        },
        store() {
            let params = {
                empleado_id             : this.empleado_id,
                periodo_incidencia_id   : !this.temporal?this.objPeriodoIncidencia.id:this.objPeriodoIncidencia.periodo_incidencia_id,
                capturo_id              : this.objPeriodoIncidencia.usuario_captura_id,
                tipo_incidencia_id      : this.tipo_incidencia_id,
                repetir                 : !this.temporal?this.repetir:false,
                tipo_indemnizacion      : this.objPeriodoIncidencia.tipo_periodo_especial == "FINIQUITO" ? this.tipo_indemnizacion : false
                                        
            };
            if(this.itemsCampos.length == 0){
                let ref = this.$refs.dynamicInput;
                ref.validate().then(success=>{
                    if(success){
                       params.valores  = ref.getData();
                       this.saveComplements(params); 
                    }
                });
            }else{
                const validateItems = new Promise((resolve) =>{
                    params.horas = [];
                    let ref = this.$refs.componentHoras;
                    params.horas = ref.getData()
                    if(params.horas.length != 0) 
                        resolve();
                    /* for(let item of this.itemsCampos){
                        let ref = this.$refs['dynamicInput'+item[0].key][0];
                        ref.validate().then(success=>{
                            if(success){
                                params.horas.push(ref.getData());

                                if(params.horas.length == this.itemsCampos.length) 
                                    resolve();
                            }
                        });
                    }  */
                });
                validateItems.then(()=>{ this.saveComplements(params) });                
            }
        },
        saveComplements(params){
            //console.log(params);
            this.btnStore = true;
            IncidenciasNominasApi.store(params)
                .then(response => {
                    let tipo = null;
                    let multiple = null;
                    this.tipos_incidencias.forEach(item =>{
                        if(item.id == this.tipo_incidencia_id){
                            tipo = item.codigo;
                            multiple = item.multiple;
                        }
                    })
                    //console.log("response",response.data)
                    if(Array.isArray(response.data)){
                        //console.log("entra")
                        for(let data of response.data){
                            data.tipo_incidencia_id = data.tipo_incidencia_id;
                            data.tipo = data.codigo;
                            data.tipoCatalogo = this.tipo;
                            data.multiple = multiple;
                            data.descripcion = this.descripcion;
                            this.indexIncidencia = this.indexIncidencia+1;
                            data.indexIncidencia = this.indexIncidencia;
                            let adicional = {};
                            this.addEmployeeComplement(data);
                            if(data.adicionales.length != 0){
                                adicional.tipo = data.adicionales[0].tipo;
                                adicional.tipo_incidencia_id = data.adicionales[0].tipo_incidencia_id;
                                adicional.totales = {valor_incidencia:data.adicionales[0].valor_incidencia};
                                adicional.repetir = data.repetir;
                                adicional.tipo_indemnizacion = data.tipo_indemnizacion;
                                adicional.campos = [];
                                adicional.tipoCatalogo = 'deducciones';
                                adicional.multiple = false;
                                adicional.descripcion = "";
                                adicional.indexIncidencia = data.indexIncidencia;
                                this.addEmployeeComplement(adicional);
                            }
                            //this.addEmployeeComplement(data);
                            this.forceRerender();
                            this.descripcion = "";
                            this.repetir = false;
                            this.tipo_indemnizacion = false;
                        }
                    }else{
                        response.data.tipo_incidencia_id = response.data.tipo_incidencia_id;
                        response.data.tipo = response.data.codigo;
                        response.data.tipoCatalogo = this.tipo;
                        response.data.multiple = multiple;
                        response.data.descripcion = this.descripcion;
                        response.funcion_nombre = this.funcion;
                        this.indexIncidencia = this.indexIncidencia+1;
                        response.data.indexIncidencia = this.indexIncidencia;
                        let adicional = {};
                        this.addEmployeeComplement(response.data);
                        if(response.data.adicionales.length != 0){
                            adicional.tipo = response.data.adicionales[0].tipo;
                            adicional.tipo_incidencia_id = response.data.adicionales[0].tipo_incidencia_id;
                            adicional.totales = {valor_incidencia:response.data.adicionales[0].valor_incidencia};
                            adicional.repetir = response.data.repetir;
                            adicional.tipo_indemnizacion =  response.tipo_indemnizacion;
                            adicional.campos = [];
                            adicional.tipoCatalogo = 'deducciones';
                            adicional.multiple = false;
                            adicional.descripcion = "";
                            adicional.indexIncidencia = response.data.indexIncidencia;
                            this.addEmployeeComplement(adicional);
                        }
                        //this.addEmployeeComplement(response.data);
                        this.forceRerender();
                        this.descripcion = "";
                        this.repetir = false;
                        this.tipo_indemnizacion = false;
                    }
                    this.btnStore = false;
                    
                })
                .catch(err => {
                    this.btnStore = false;
                    this.forceRerender();
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                } );
        },
        addEmployeeComplement(data){
            let empleado = null;
            this.dataNew = data;
            let self = this;
            if(this.tableData.length > 0){
                for(let item of this.tableData){
                    if(this.empleado_id == item.id){
                        //console.log("editar");
                        for(let item2 of item.incidencias){
                            if((item2.tipo == data.tipo) && (this.funcion !== 'p_HorasExtrasDobles' && this.funcion !== 'p_HorasExtrasTriples')){

                                Notify.Alert(
                                    "Ya existe un tipo de complemento igual",
                                    "¿Deseas agregarlo?",
                                    "Aceptar",
                                    ()=>{
                                        for(let item of self.tableData){
                                            item.incidencias.push(self.dataNew);
                                        }
                                        
                                        self.tableData = JSON.parse(JSON.stringify(self.tableData));
                                    }
                                    );
                                    return
                            }
                        }
                        item.incidencias.push(data);
                        return;
                    }
                }
                //console.log("añadir");
                empleado = this.getEmployee();
                empleado.incidencias = [data];
                this.tableData.push(empleado);
            }else{
                //console.log("añadir nuevo");
                empleado = this.getEmployee();
                empleado.incidencias = [data];
                this.tableData.push(empleado);
            }
            //console.log("TD",this.tableData);
        },
        getEmployee(){
            let empleado = null;
            for(let employee of this.itemsEmpleados){
                if(this.empleado_id == employee.id){
                    empleado = employee;
                    break;
                }
            }
            return empleado;
        },
        cancel() {
            this.$modal.hide('incidenciasModal');
        },
        preGuardar(){
            let mensajeAdvertencia = 0;
            if(this.tableData.length != 0){
                this.tableData.forEach(item => {
                    if(item.incidencias.length > 0){
                        item.incidencias.forEach(item2 =>{
                            if(item2.totales.valor_incidencia < 0){
                                if(mensajeAdvertencia < 1){
                                    mensajeAdvertencia = mensajeAdvertencia + 1;
                                }
                            }
                        });
                    }
                });
                if(mensajeAdvertencia == 1){
                    Notify.Error(
                        "ADVERTENCIA",
                        "Uno o más complementos presentan errores.", 
                        () => {
                            !this.temporal?this.guardar():this.guardarTemporal();
                        }, 
                        'Cerrar',
                        
                    )
                }
                else if (mensajeAdvertencia == 0){
                    !this.temporal?this.guardar():this.guardarTemporal();
                }
            }
        },
        guardar(){
            let empleados_incidencias = [];
            let complementos = [];
            this.disableGuardar = true;
            if(this.tableData.length != 0){
                this.tableData.forEach(item => {
                    if(item.incidencias.length > 0){
                        complementos = [];
                        item.incidencias.forEach(item2 =>{
                            let datos = {
                                valor1      : null,
                                valor2      : null,
                                valor3      : null,
                                total       : null,
                                descripcion : null,
                                indexIncidencia: null,
                                codigo      : null,
                                repetir     : false,
                                tipo_indemnizacion : false,
                            };
                            if(item2.campos[0]){
                                datos.valor1 = item2.campos[0].value;
                            }
                            if(item2.campos[1]){
                                datos.valor2 = item2.campos[1].value;
                            }
                            if(item2.campos[2]){
                                datos.valor3 = item2.campos[2].value;
                            }
                            datos.total = item2.totales.valor_incidencia;
                            if(item2.tipoCatalogo == "percepciones"){
                                datos.totales =  item2.totales;
                            }
                            datos.descripcion           = item2.descripcion;
                            datos.tipo_incidencia_id    = item2.tipo_incidencia_id;
                            datos.indexIncidencia       = item2.indexIncidencia;
                            datos.codigo                = item2.tipo;
                            datos.horas                 = item2.horas;
                            datos.repetir               = item2.repetir != undefined ? item2.repetir : false;
                            datos.tipo_indemnizacion    = item2.tipo_indemnizacion != undefined ? item2.tipo_indemnizacion : false;
                            complementos.push(datos);
                        });
                    }
                    let empleado = {id:item.id,incidencias:complementos};
                    empleados_incidencias.push(empleado);
                });
            }
            
            let params = {
                capturo_id              : this.objPeriodoIncidencia.usuario_captura_id,
                periodo_incidencia_id   : this.objPeriodoIncidencia.id,
                tipo_incidencia_id      : this.tipo_incidencia_id,
                empleados               : empleados_incidencias
            };

            IncidenciasNominasApi.storeIncidencias(params).then(response => {
                Notify.Success("Operación exitosa", "Incidencias registradas satisfactoriamente.");
                this.disableGuardar = false;
                this.$modal.hide('incidenciasModal');
            })
            .catch(err => {
                this.disableGuardar = false;
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            });
        },
        guardarTemporal(){
            this.disableGuardar = true;
            let empleados_incidencias = [];
            let complementos = [];
            if(this.tableData.length != 0){
                this.tableData.forEach(item => {
                    if(item.incidencias.length > 0){
                        complementos = [];
                        item.incidencias.forEach(item2 =>{
                            let datos = {
                                valor1      : null,
                                valor2      : null,
                                valor3      : null,
                                total       : null,
                                descripcion : null,
                                indexIncidencia: null,
                                codigo      : null,
                                tipo_indemnizacion : false,
                            };
                            if(item2.campos[0]){
                                datos.valor1 = item2.campos[0].value;
                            }
                            if(item2.campos[1]){
                                datos.valor2 = item2.campos[1].value;
                            }
                            if(item2.campos[2]){
                                datos.valor3 = item2.campos[2].value;
                            }
                            datos.total = item2.totales.valor_incidencia;
                            if(item2.tipoCatalogo == "percepciones"){
                                datos.totales =  item2.totales;
                            }
                            datos.descripcion           = item2.descripcion;
                            datos.tipo_incidencia_id    = item2.tipo_incidencia_id;
                            datos.indexIncidencia       = item2.indexIncidencia;
                            datos.codigo                = item2.tipo;
                            datos.horas                 = item2.horas;
                            datos.tipo_indemnizacion    = item2.tipo_indemnizacion != undefined ? item2.tipo_indemnizacion : false;
                            complementos.push(datos);
                        });
                    }
                    let empleado = {id:item.id,incidencias:complementos};
                    empleados_incidencias.push(empleado);
                });
            }

            let params = {
                capturo_id      : this.$session.get('usuario').id,
                empleado_id     : this.empleado_id,
                periodo_id      : this.objPeriodoIncidencia.id,
                empleados       : empleados_incidencias
            };
            IncidenciasNominasApi.storeIncidenciasTemporales(params).then(response => {
                Notify.Success("Operación exitosa", "Incidencias registradas satisfactoriamente.");
                this.disableGuardar = false;
                this.$modal.hide('incidenciasModal');
            })
            .catch(err => {
                this.disableGuardar = false;
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            });
        },
        async eliminar(item,employeeIndex){
            
            let originalIndex = item.indexIncidencia;
            let employees = JSON.parse(JSON.stringify(this.tableData));
            let employee = employees[employeeIndex];
            let incidencias = employee.incidencias;

            for(var i = incidencias.length - 1; i>=0 ;i--){
                if(incidencias[i].indexIncidencia == originalIndex){
                    incidencias.splice(i,1);
                }  
            }

            if(incidencias.length == 0){
                employees.splice(employeeIndex,1);
            }

            this.tableData = employees;
        },
        async scrollPerfect(){
            var myCustomScrollbar = document.querySelector('.scrollbar-template');
            var ps = new PerfectScrollbar(myCustomScrollbar);

            var scrollbarY = myCustomScrollbar.querySelector('.ps__rail-y');

            myCustomScrollbar.onscroll = function () {
                scrollbarY.style.cssText = `
                                                top: ${this.scrollTop}px!important; 
                                                height: 200px; 
                                                background-color:transparent; 
                                                right: ${-this.scrollLeft}px
                                            `;
            }
        },
        updateUsedDates(){
            let semana = 0;
            let setDate = false;
            let dates = [];
            let weekComponets = [];

            //ordenar fechas
            for(let item of this.itemsCampos){
                let ref = this.$refs['dynamicInput'+item[0].key];
                if(ref != undefined){
                    let date = ref[0].getData()[1].value;
                    if(date != undefined && date != "" && date != null){
                        dates.push(date);
                        dates = dates.sort();
                    }
                }
            }

            
            //console.log(dates);
            if(dates.length > 0)
                this.usedDates = dates;

            for(let fecha of this.usedDates){
                for(let item of this.itemsCampos){
                    setDate = false;
                    let ref = this.$refs['dynamicInput'+item[0].key];
                    if(ref != undefined){
                        let date = ref[0].getData()[1].value;
                        if(fecha == date){
                             semana = 0;
                            for(let week of this.weekHours){
                                //console.log("f",week.fechas);
                                for(let fechaWeek of week.fechas){
                                    if(fechaWeek.isSame(date) && !setDate){
                                        if(weekComponets[semana] == undefined)
                                            weekComponets[semana]=[];
                                        weekComponets[semana].push(item);
                                        setDate=true;
                                        let data = ref[0].getData();
                                        this.renderItemsCampos(item,data);
                                        break;
                                    } 
                                }
                                if(weekComponets[semana] == undefined)
                                    weekComponets[semana]=[];
                                
                                semana++;
                            }
                            if(weekComponets[semana] == undefined)
                                weekComponets[semana]=[];
                        }else if(date == null || date == undefined || date == ""){
                            //console.log(1);
                            weekComponets[this.weekHours.length] = [item];
                            break;
                        }
                    }else{
                        if(weekComponets[this.weekHours.length] == undefined)
                                weekComponets[this.weekHours.length]=[];
                        //console.log(2);
                        weekComponets[this.weekHours.length] = [item];
                        break;
                    }
                }
            }

            /*for(let i = 0; this.usedDates.length > i; i++){
                let usedDate = this.usedDates[i];
                console.log("UD",usedDate);
                for(let week of this.weekHours){
                    console.log("f",week.fechas);
                    for(let fecha of week.fechas){
                        if(fecha.isSame(usedDate)){
                            if(weekComponets[semana] == undefined)
                                weekComponets[semana]=[];
                            weekComponets[semana].push(this.itemsCampos[i]);
                            sinFecha=false;
                        } 
                    }
                    if(weekComponets[semana] == undefined)
                        weekComponets[semana]=[];
                    
                    semana++;
                }
                weekComponets[semana] = [];
                if(sinFecha){
                    weekComponets[semana].push(this.itemsCampos[i+1]);
                }
                
            }*/
            
            //console.log("w",weekComponets);
            if(weekComponets.length > 0)
                this.weekComponets = weekComponets;

            this.$nextTick(()=>{
                //this.updateWeekHours();
            })
            
        },
        setWeekHours(){
            const limit = 7;
            const maxHorasDobles = 9;
            let date = moment(this.inicio_periodo);
            let count = 0;
            let week = 0;
            let weeks = [];

            while(!date.isSameOrAfter(moment(this.final_periodo).add(1,"d"))){
                if(count == 0){
                    weeks.push({fechas:[],horasDobles:maxHorasDobles});
                }
                    
                if(count < limit){
                    let newDate = moment(date.format("YYYY-MM-DD"));
                    weeks[week].fechas.push(newDate);
                    date = date.add(1,"d");
                    count++;
                }else{
                    count = 0;
                    week++;
                }
            }

            this.weekHours = weeks;
        },
        updateWeekHours(){
            const topeDoblesDiario = 3;
            this.setWeekHours();
            for(let item of this.itemsCampos){
                let ref = this.$refs['dynamicInput'+item[0].key][0];
                let index = 0;
                if(ref != undefined){
                    let hours = ref.getData()[0].value;
                    let date = ref.getData()[1].value;
                    let topeDobles = 0;
                    let horasExtra = [];

                    if(hours != undefined && hours > 0 && date != undefined){
                        for(let week of this.weekHours){
                            for(let fecha of week.fechas){
                                topeDobles = week.horasDobles;
                                if(fecha.isSame(date)){
                                    if(topeDobles > 0){
                                        if(hours <= topeDoblesDiario){
                                            if(hours <= topeDobles){
                                                horasExtra.push({tipo:"Horas Dobles",horas:hours});
                                                topeDobles = topeDobles - hours;
                                            }else{
                                                horasExtra.push({tipo:"Horas Dobles",horas:topeDobles});
                                                horasExtra.push({tipo:"Horas Triples",horas:hours-topeDobles});
                                                topeDobles = topeDobles - topeDobles;
                                            }
                                            
                                        }else if(hours > topeDoblesDiario){
                                            if(topeDobles < topeDoblesDiario){
                                                horasExtra.push({tipo:"Horas Dobles",horas:topeDobles});
                                                horasExtra.push({tipo:"Horas Triples",horas:hours-topeDobles});
                                                topeDobles = topeDobles - topeDobles;
                                            }else{
                                                horasExtra.push({tipo:"Horas Dobles",horas:topeDoblesDiario});
                                                horasExtra.push({tipo:"Horas Triples",horas:hours-topeDoblesDiario});
                                                topeDobles = topeDobles - topeDoblesDiario;
                                            }
                                        }
                                    }else{
                                        horasExtra.push({tipo:"Horas Triples",horas:hours});
                                    }
                                    //console.log(topeDobles);
                                    this.weekHours[index].horasDobles = topeDobles;
                                    ref.setHours(horasExtra);
                                }
                            }
                            index++;
                        }
                    } 
                }
            }
                
            //console.log("wkt",this.weekHours);
        },
        agregarCampo(){  
            this.componentKey++; 
            let campos = JSON.parse( JSON.stringify(this.campos));
            campos[0].key = this.componentKey;
            this.itemsCampos.push(campos);
            this.$nextTick(()=>{
                this.updateUsedDates();
            });
            
            
        },
        eliminarCampo(index){
            //console.log(index);
            this.itemsCampos = this.itemsCampos.filter(item=>item[0].key != index);
            //this.itemsCampos.splice(index,1);

            if(this.itemsCampos.length == 0){
                this.tipo_incidencia_id = null;
                this.horasExtra = false;
                this.campos = [];
                this.usedDates = [];
            }

            this.$nextTick(()=>{
                this.updateUsedDates();
            });
        },
        renderItemsCampos(item,data){
            this.$nextTick(()=>{
                let ref = this.$refs['dynamicInput'+item[0].key][0];
                if(ref != undefined)
                    if(data[0].value != undefined && data[0].value > 0 && data[1].value != undefined)
                        ref.setData([data[0].value,data[1].value])
            });
        },
        forceRerender () {
            this.componentKey++;  
        },
        dateFormat(fecha) {
            if(fecha == 0){
                return '';
            }
            else{
                let fecha2 = fecha.split("-");
                return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
            }
        },
        formatoMoneda(value){
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(value)
        },
        getEmpleadosPTU() {
            let params = {
                rol_name: this.rol,
                usuario_id: this.datosLogin.id,
                periodoPTU:this.objPeriodoIncidencia.periodo_id,
                cliente_id:this.objPeriodoIncidencia.cliente_id,
                tipo_nomina_id:this.objPeriodoIncidencia.tipo_nomina_id,
                paginate:false
            };

            IncidenciasNominasApi.getEmpleadosPTU(params).then(response => {
                this.itemsEmpleados = response.data;
            })
            .catch(error => console.error(error));
        },
    },
    created() {
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;
        this.temporal = this.$session.get('temporal');
        if(!this.temporal){
            this.getEmpleados();
        }
        
        this.getTiposIncidencias();
    },
    mounted() {
        //this.inicio_periodo = this.objPeriodoIncidencia.inicio_captura;
        //this.final_periodo = this.objPeriodoIncidencia.final_captura;
        this.scrollPerfect();

        if(this.temporal){
            if(this.objPeriodoIncidencia.tipo_periodo_especial == "AGUINALDO"){
                this.$apollo.queries.empleadosAguinaldo.skip = false;
            }
            else if(this.objPeriodoIncidencia.tipo_periodo_especial == "FINIQUITO"){
                this.$apollo.queries.empleadosFiniquito.skip = false;
            }
            else if(this.objPeriodoIncidencia.tipo_periodo_especial == "PTU"){
                this.getEmpleadosPTU();
            }

            //this.inicio_periodo = this.objPeriodoIncidencia.fecha_inicial;
            //this.final_periodo = this.objPeriodoIncidencia.fecha_final;
            this.inicio_periodo = this.objPeriodoIncidencia.fecha_inicial;
            this.final_periodo = this.objPeriodoIncidencia.fecha_final;
        }
        else{
            this.inicio_periodo = this.objPeriodoIncidencia.periodoModel.fecha_inicial;
            this.final_periodo = this.objPeriodoIncidencia.periodoModel.fecha_final;
        }

    },
    apollo: {
        empleadosFiniquito: {
            query       : queries.getEmpleadosQueryFiniquito,
            fetchPolicy : 'network-only',
            manual: true,
            variables() {
                return {
                    whereConditions : {
                        AND:[
                                {column:'CLIENTE_ID',value:this.objPeriodoIncidencia.cliente_id},
                                {column:'TIPO_NOMINA_ID', value: this.objPeriodoIncidencia.tipo_nomina_id},
                                {column:'FECHA_COBRO_FINIQUITO',operator:'IS_NULL'}
                            ]
                    },
                    numberItems     : 10000000,
                    numberPage      : 1,
                    fieldOrder      : 'nombre',
                    Order           : 'ASC'
                }
            },
            skip() {
                return true
            },
            result({data,loading, error}) {
                if(error != undefined){
                    console.log("Error graphQL empleados");
                }
                else{
                    this.itemsEmpleados = data.empleados.data;
                }
            }

        },
        empleadosAguinaldo : {
            query       : queries.getEmpleadosQueryAguinaldo,
            fetchPolicy : 'network-only',
            manual: true,
            variables() {
                return {
                    whereConditions : {
                        AND:[
                                {column:'CLIENTE_ID',value:this.objPeriodoIncidencia.cliente_id},
                                {column:'TIPO_NOMINA_ID', value: this.objPeriodoIncidencia.tipo_nomina_id},
                                {column:'FECHA_COBRO_FINIQUITO',operator:'IS_NULL'}
                            ]
                    },
                    numberItems     : 10000000,
                    numberPage      : 1,
                    fieldOrder      : 'nombre',
                    Order           : 'ASC'
                }  
            },
            skip() {
                return true
            },
            result({data, loading, error}) {
                if(error != undefined){
                    console.log("Error graphQL empleados");
                }
                else{
                    this.itemsEmpleados = data.empleados.data;
                }
            }
        }
    },
}
</script>

<style scoped>
    .header {
        text-align: center !important;
        padding-top: 58px;
    }

    .title {
        font-family: 'Montserrat' !important;
        font-style: normal;
        font-weight: 500;
        font-size: 24px !important;
        line-height: 29px;
        color: #1E2245;
    }

    .container {
        background-color: #fff;
        padding: 30px 37px;
        width: 100%;
    }

    /**
     * TABLE
     */
     th{
        color:#828282;
        font-family: 'Montserrat';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        padding: 8px;
        text-align: left;
    }

    .tbl-incidencias tbody td{
        color: #1E2144;
        font-family: 'Montserrat';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        padding: 8px;
        text-align: left;
    }
    tbody tr:nth-child(1n){
        /* background-color: #f2f2f2; */
        /* border-bottom-style: solid; 
        border-bottom-width: 1px;
        border-bottom-color: #C4C4C4; */
    }

    .tbl-incidencias {
        border-collapse: collapse;
        width: 100%;
        margin-top: 15px;
        /* white-space: nowrap; */
        overflow-x: scroll;
        overflow-y: scroll;
    }

    .tbl-incidencias .tbl-header {
        background: #F6F6F6;
        border-radius: 10px;
        height: 58px;
        /* border-top-left-radius: 20px;
        border-top-right-radius: 20px; */
    }
    th:first-child {
        border-top-left-radius: 20px !important;
    }
    th:last-child {
        border-top-right-radius: 20px;
    }
    .emptyTableComplementos{
        color: #c5c5c5 !important;
        font-family: "Montserrat" !important;
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 22px !important;
        padding-bottom: 50px !important;
        padding-top: 50px !important;
        text-align: center !important;
    }
</style>