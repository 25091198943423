import Vue from 'vue';

export default {
    getIncidencias(parametros){
        return Vue.axios.get("incidencias/find", {params: parametros}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getIncidencia(id){
        return Vue.axios.get("incidencias/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateIncidencia(parametros){
        return Vue.axios.post("incidencias/update", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    deleteIncidencia(id){
        return Vue.axios.delete("incidencias/"+ id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    addIncidencia(parametros){
        return Vue.axios.post("incidencias", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    upload_documento(parametros){
        return Vue.axios.post("incidencias/upload_documento", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    donwloand(parametros){
        return Vue.axios.post("incidencias/download", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    cancelar(parametros){
        return Vue.axios.post("incidencias/delete", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
}