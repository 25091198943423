<template>
    <ValidationObserver ref="form" v-slot="{ invalid }" style="width:100%">
        <v-row style="width:100%">
            <template v-for="(item, index) in items">
                <v-col :key="index" cols="12" xs="12" sm="4" md="3" class="pt-0 pb-0 ma-0">
                    <template v-if="item.tipo == 'STRING'">
                        <ValidationProvider v-slot="{ errors }" :name="item.nombre" :rules="fields.rule[index]">
                            <v-text-field
                                outlined
                                :label="item.nombre"
                                class="pa-0 ma-0" 
                                v-model="fields.values[index]"
                                :id="'text-field-' + item.id"
                                required 
                                :error-messages="errors"
                                :disabled="fields.disabled[index]"
                            />
                        </ValidationProvider>
                    </template>

                    <template v-if="item.tipo == 'INTEGER'">
                        <ValidationProvider v-slot="{ errors }" :name="item.nombre" :rules="fields.rule[index]">
                            <v-text-field
                                :id="'text-field-' + item.id"
                                v-model="fields.values[index]"
                                @change="funcion == 'p_HorasExtrasDobles' ? updateWeekHours():null"
                                outlined
                                :label="item.nombre"
                                v-mask="'#######'"
                                class="pa-0 ma-0"
                                required 
                                :error-messages="errors"
                                :disabled="fields.disabled[index]"
                            />
                        </ValidationProvider>
                    </template>

                    <template v-if="item.tipo == 'FLOAT'">
                        <template v-if="item.codigo == 'importe'">
                            <VuetifyMoneyValidate
                                outlined
                                :label="item.nombre"
                                class="pa-0 ma-0"
                                prefix="$"
                                v-model="fields.values[index]"
                                v-bind:options="options"
                                :id="'text-field-' + item.id"
                                :rules="fields.rule[index]"
                                :disabled="fields.disabled[index]"
                            />
                        </template>
                        <template v-else-if="item.codigo == 'dias' || item.codigo == 'anios'">
                            <ValidationProvider v-slot="{ errors }" :name="item.nombre" :rules="fields.rule[index]">
                                <v-text-field
                                    outlined
                                    :label="item.nombre"
                                    class="pa-0 ma-0"
                                    v-on:keypress="presicionNumero($event,fields.values[index])"
                                    oninput="if(this.value<0) this.value = 0; this.value=this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                    v-model="fields.values[index]" 
                                    :id="'text-field-' + item.id"
                                    required 
                                    :error-messages="errors"
                                    :disabled="fields.disabled[index]"
                                >
                                    <template v-if="isTooltip" v-slot:append-outer>
                                        <v-tooltip top class="pb-7">
                                            <template v-slot:activator="{ on }">
                                                <v-icon color='#96999A' v-on="on" slot="activator" class="pb-7 pl-1 outlined_v_icon">{{nameIcon}}</v-icon>
                                            </template>
                                            <span class="pb-7 textTooltip" v-html="textTooltip"></span>
                                        </v-tooltip>  
                                    </template>
                                </v-text-field>
                            </ValidationProvider>
                        </template>
                        <template v-else>
                            <!-- campo para porcentaje -->
                            <ValidationProvider v-slot="{ errors }" :name="item.nombre" :rules="fields.rule[index]">
                                <v-text-field
                                    outlined
                                    :label="item.nombre"
                                    class="pa-0 ma-0"
                                    v-on:keypress="presicionNumero($event,fields.values[index])"
                                    oninput="if(this.value > 100 || this.value<0) this.value = 100; this.value=this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                    v-model="fields.values[index]" 
                                    suffix="%"
                                    :id="'text-field-' + item.id"
                                    required 
                                    :error-messages="errors"
                                    :disabled="fields.disabled[index]"
                                />
                            </ValidationProvider>
                        </template>
                    </template>

                    <template v-if="item.tipo == 'DATE' && funcion == 'd_Ausencia'">
                        <v-date-picker
                            class="datePickerMultiple"
                            v-model="fields.values[index]"
                            locale="es-MX"
                            v-bind:min="min"
                            v-bind:max="max"
                            multiple
                            scrollable
                            offset-y
                            no-title
                            max-width="290px"
                            min-width="290px"
                            color="primary"
                        >      
                        </v-date-picker>
                    </template>
                    <template 
                        v-else-if="
                            item.tipo == 'DATE' && 
                            (funcion == 'p_Vacaciones' || funcion == 'p_VacacionesFiniquito')"
                    >
                        <VuetifyDatePicker
                            outlined
                            readonly
                            v-model="fields.values[index]"
                            :label="item.nombre"
                            placeholder="dd/mm/aaaa"
                            ref="Fecha"
                            rules="required"
                            @updateNotAllowedDates="updateNotAllowedDates"
                            :notAllowedDates="usedDates"
                        />
                    </template>
                    <template v-else-if="item.tipo == 'DATE' && funcion != 'd_Ausencia'">
                        <VuetifyDatePicker
                            outlined
                            readonly
                            v-model="fields.values[index]"
                            :label="item.nombre"
                            placeholder="dd/mm/aaaa"
                            :min="min"
                            :max="max"
                            ref="Fecha"
                            rules="required"
                            @updateNotAllowedDates="updateNotAllowedDates"
                            :notAllowedDates="usedDates"
                        />
                    </template>
                    

                    <template v-if="item.tipo == 'BOOL'">
                        <v-checkbox 
                            class="checkboxVuetify" 
                            v-model="fields.values[index]" 
                            :label="item.nombre"
                            :id="'text-field-' + item.id"
                        ></v-checkbox>
                    </template>
                    
                    <template v-if="item.tipo == 'select'">
                        <v-autocomplete
                            outlined
                            :items="item.valor"
                            item-text="nombre"
                            item-value="clave"
                            :label="item.nombre"
                            persistent-hint
                            v-model="fields.values[index]"
                            no-data-text="Datos no disponibles"
                            :id="'autocomplete-' + item.id"
                        >
                        </v-autocomplete>
                    </template>
                </v-col>
            </template>
        
            <template v-if="funcion == 'p_HorasExtrasDobles' && horasExtra.length > 0">
                <v-col cols="12" xs="12" sm="4" md="3" class="py-0 my-0">
                    <v-row class="my-0">
                        <template v-for="(hour,i) in horasExtra">
                            <v-col :key="i" cols="12" xs="12" sm="12"  class="pt-0">
                                <span  :class="['badge', hour.tipo.includes('D')?'green':'blue']">{{hour.tipo}}: {{hour.horas}}</span> 
                            </v-col>
                        </template>
                    </v-row>
                </v-col>
            </template>
            <template v-if="actions">
                <v-col cols="12" xs="12" sm="4" md="2" class="py-0 my-0">
                <v-row class="my-0"><v-col v-if="last" cols="12" xs="12" sm="4" md="4" class="py-2 my-0">
                        <v-btn  class="" color="#1E2245"  elevation="0" dark fab  @click="agregar()" small>
                            <v-icon dark>add</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" xs="12" sm="4" md="4" class="py-2 my-0">
                        <v-btn class="botonHover" icon fab dark small @click="eliminar()" >
                            <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                        </v-btn>
                    </v-col>
                    
                </v-row>
                </v-col>
            </template>
        </v-row> 
    </ValidationObserver>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
import VuetifyMoneyValidate from '@/components/VuetifyMoneyValidate.vue';
import Notify from '@/plugins/notify';

export default {
    components: {
        VuetifyDatePicker,
        VuetifyMoneyValidate,
    },
    name: 'dynamic-input',
    props: {
        items: { 
            type: Array, 
            default: () => [] 
        },
        usedDates: { 
            type: Array, 
            default: () => [] 
        },
        weekHours: { 
            type: Array, 
            default: () => [] 
        },
        keyItem: {
            type: Number,
            default: undefined
        },
        funcion: {
            type: String,
            default: undefined
        },
        last:{
            type: Boolean,
            default: false
        },
        edit: {
            type: Boolean,
            default: false
        },
        actions: {
            type: Boolean,
            default: false
        },
        min: {
            type: String,
            default: undefined
        },
        min: {
            type: String,
            default: undefined
        },
        max: {
            type: String,
            default: undefined
        },
        isWatch: {
            type: Boolean,
            default: false
        }
    },
    computed:{
        isTooltip(){
            if(this.funcion == 'p_PrimaVacacional' || this.funcion == 'p_PrimaVacacionalFiniquito'){
                this.nameIcon       = "info";
                this.textTooltip    = "El calculo del complemento se determina con el salario diaro por los dias ingresados en el campo, <br>al resultado se multiplica el porcentaje minimo de ley de prima vacacional (25%)";
                return true
            }
            else{
                return false
            }
        }
    },
    watch: {
        "fields.values": function (newValue) {
            if(this.isWatch){
                
                let tipoFecha = false;

                if(this.items.length>=2){
                    if(this.items[0].codigo == 'fecha' || this.items[1].codigo == 'fecha'){
                        tipoFecha = true;
                    }
                }
                if (this.fields.values[0] != undefined && this.fields.values[0] != "" && this.fields.values[0] != null && tipoFecha == false){
                    this.fields.disabled[0] = false;
                    this.fields.disabled[1] = true;
                    this.fields.values[1] = "";
                }
                else if (this.fields.values[1] != undefined && this.fields.values[1] != ""  && this.fields.values[1] != null && tipoFecha == false){
                    this.fields.disabled[0] = true;
                    this.fields.disabled[1] = false;
                    this.fields.values[0] = "";
                }
            }


            if(this.funcion !== "p_HorasExtrasDobles" && this.funcion !== "p_HorasExtrasTriples"){
                //console.log(this.fields.values[0]);
                if((this.fields.values[0] == undefined || this.fields.values[0] == "") && (this.fields.values[1] == undefined || this.fields.values[1] == "")){
                    this.fields.disabled[0] = false;
                    this.fields.disabled[1] = false;
                }else{
                    let date = false;
                    for (let index = 0; index < this.items.length; index++) {
                        let codigo = this.items[index].codigo;
                        if(codigo == "fecha" && this.items.length == 2)
                            date = true;
                    }
                    /* if(!date){
                        if(this.fields.values[0] !== undefined && this.fields.values[0] !== ""){
                            this.fields.disabled[0] = false;
                            this.fields.disabled[1] = true;
                        }else if(this.fields.values[1] !== undefined && this.fields.values[1] !== ""){
                            this.fields.disabled[0] = true;
                            this.fields.disabled[1] = false;
                        }
                    } */
                }
            }
        }
    },
    data() {
        return {
            fields: {
                values: [],
                rule: [],
                disabled:[]
            },
            horasExtra:[],
            menu : false,
            options: {
                locale: "es-MX",
                prefix: "$",
                suffix: "",
                length: 11,
                precision: 2
            },
            optionPorcentaje: {
                locale: "es-MX",
                prefix: "",
                suffix: "%",
                length: 5,
                precision: 2
            },
            nameIcon:"info",
            textTooltip:'',
        }
    },
    methods: {
        validate() {
                if(this.funcion == "p_HorasExtrasDobles"){
                    this.fields.rule[0] = "required";
                }
                else if(this.funcion == 'd_Ausencia'){
                    //console.log(this.fields);
                    if(this.fields.values.length == 0){
                        Notify.ErrorToast("Favor de completar los campos faltantes.");
                        return;
                    }
                    else{
                        if(this.fields.values[0] == undefined){
                            this.fields.values[0] = 0;
                        }
                        if(this.fields.values[1] == undefined){
                            this.fields.values[1] = false;
                        }
                        else{
                            this.fields.values[1]= JSON.stringify(this.fields.values[1])
                        }

                        if(this.fields.values[2] == undefined){
                            this.fields.values[2] = "";
                        }
                        else{
                            this.fields.values[2]= JSON.stringify(this.fields.values[2])
                        }

                    }

                    /* let dias = parseInt(this.fields.values[0]);
                    let numFechas = this.fields.values[1].length;
                    if(dias != numFechas){
                        Notify.ErrorToast("El número de días no concuerda con el número de fechas seleccionadas.");
                        return new Promise((resolve)=>{resolve(false)}); 
                    }
                    else{
                        this.fields.values[1]= JSON.stringify(this.fields.values[1])
                    } */
                }
                else{
                    let notFill = 0;
                    let minLength = this.items.length
                    for (let index = 0; index < this.items.length; index++) {
                        let codigo = this.items[index].codigo;
                        let valor = this.fields.values[index];
                        //if para validar de que se ingresen valores

                         /* Se quita la validacion de p_Sueldo */
                        if(
                            this.funcion !== "d_Imss" &&
                            this.funcion !== "o_SubsidioEntregar" &&
                            this.funcion !== "o_SubsidioCausado" &&
                            this.funcion !== "o_SubsidioPagadoNoCorrespondia" &&
                            this.funcion !== "d_IsrAjusteMensual" &&
                            this.funcion !== "o_IsrAjustadoSubsidio" &&
                            this.funcion !== "d_AjusteSubsidioCausado" &&
                            this.funcion !== "d_AjusteSubsidioPagado" &&
                            this.funcion !== "d_Infonavit" &&
                            this.funcion !== "p_VacacionesFiniquito" &&
                            this.funcion !== "p_PrimaVacacionalFiniquito" &&
                            this.funcion !== "p_PrimaAntiguedad" &&
                            this.funcion !== "p_Indemnizacion" &&
                            //this.funcion !== "p_Aguinaldo"&&
                            this.funcion !== "d_IsrRetener"
                            
                        ){
                            if(valor == undefined || valor == "" || valor == "0.00"){
                                notFill++;
                            }
                        }
                        if(codigo == "fecha"){
                            minLength--;
                            this.$refs.form.validate();
                        } 
                    }
                    if(notFill == minLength){
                        if(minLength == 1){
                            Notify.ErrorToast("Favor de completar el campo faltante.");
                            return new Promise((resolve)=>{resolve(false)});
                        }
                        Notify.ErrorToast("Favor de completar los campos faltantes.");
                        return new Promise((resolve)=>{resolve(false)});
                    }
                }
            


            return this.$refs.form.validate();
        },
        getData() {
            let response = [];
            let valor = "";
            //console.log("fields",this.fields)
            for (let index = 0; index < this.items.length; index++) {
                const element = this.items[index];
                valor = "";
                if(this.fields.values[index]){
                    valor = this.fields.values[index];
                }else if(this.fields.values[index] == undefined){
                    valor = null
                }
                let objItem = {
                    field   : element.codigo,
                    type    : element.tipo,
                    value   : valor
                };
                response.push(objItem);
            }
            //console.log("response",response);

            return response;
        },
        setData(data){
            //console.log("setData",data);
            this.fields.values = data;
            if(this.$refs.Fecha){
                for(let item of data){
                    if(item.includes("-")){
                        this.$nextTick(()=>this.$refs.Fecha[0].setDate(item));
                    }
                }
            }
            
        },
        updateNotAllowedDates(){
            this.$emit('updateUsedDates');
        },
        updateWeekHours(){
            this.$emit("updateWeekHours");
        },
        setHours(extraHours){
            this.horasExtra = extraHours;
        },
        agregar(){
            this.validate()
                .then(success=>{
                    if(success)
                        this.$emit("agregarCampo");
                }); 
        },
        eliminar(){
            this.$emit("eliminarCampo",this.keyItem);
        },
        keyPress(data) {
            let keyCode = data.$event.keyCode ? data.$event.keyCode : data.$event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode != 46) {

                data.$event.preventDefault();
            }
            else{
                //let number = data.index;
                this.fields.values[data.index] = parseFloat(this.fields.values[data.index]).toFixed(2)

            }
        },
        presicionNumero(event, valor){
            if (/\.\d{2}/.test(valor)) 
                return event.preventDefault();
        },
    },
    created() {
        let objDefault = {};

        this.items.map(item => {
            let objNew = {

            }

            Object.assign(objDefault, objNew);
        });
    },
}
</script>

<style>
.badge {
        border-radius: 50px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        letter-spacing: 0.01em;
        color: #FFFFFF !important;
        padding: 3px 10px;
    }
    .green{
        background-color: #27AE60;
    }
    .blue{
        background-color: #5DD3E8;
    }
</style>