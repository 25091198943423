import gql from 'graphql-tag';

const usersTableQuery = gql`
    query usuarios($whereCondition: UsuariosWhereWhereConditions,$numberItems: Int!, $numberPage: Int!, $fieldOrder: String!, $Order: SortOrder!) {
        usuarios(where:$whereCondition, first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: $Order}]) {
            data {
                id,
                nombre,
                apellido_paterno,
                apellido_materno,
                telefono,
                celular,
                email,
                cliente_id,
                empresa_id,
                sucursal_id,
                departamento_id,
                rol_id,
                rol{
                    name,
                    display_name
                },
                empresa{
                    nombre
                },
                usuarioDepartamentos{
                    departamento_id
                }
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`

const queries = { usersTableQuery };

export default queries;
